import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  About,
  Contact,
  Daimond,
  Silver,
  Gold,
  Giftarticles,
  // Blog,
  // Posts,
  // Post,
} from "./Components";
import Account from "./Components/Account";
import Fashion from "./Components/Fashion";
import Goldprice from "./Components/Goldprice";
import Goldrate from "./Components/goldrate";
import Login from "./Components/Login";
export default function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/review" element={<Login />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/silver" element={<Silver />} />
        <Route path="/gold" element={<Gold />} />
        <Route path="/daimond" element={<Daimond />} />
        <Route path="/gifts" element={<Giftarticles />} />
        <Route path="/about" element={<About />} />
        <Route path="fashion" element={<Fashion />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/myaccount" element={<Account/>}/>
        <Route path="/goldrate" element={<Goldrate/>}/>
        <Route path="/goldprice" element={<Goldprice/>}/>
        {/* <Route path="/blog" element={<Blog />}>
        <Route path="" element={<Posts />} />
        <Route path=":postSlug" element={<Post />} />
      </Route> */}
      </Routes>
      <Footer />
    </Router>
  );
}
