import React from "react";
import "./styles.css";
function Footer() {
  return (
    <div className="footer">
      <div class="row py-2 bg-dark m-0  text-white">
        <div class="col">
          <div>
            <h7>JEWELLERY CARE</h7>

            <p class="small">Know your Gold</p>
            <p class="small">Know your Daimond</p>
            <p class="small">Know your Gem Stone</p>
            <p class="small">Bangle Size Guide</p>
          </div>
        </div>
        <div class="col">
          <h7>POLICIES</h7>
          <p class="small">Shipping and Delivery</p>
          <p class="small">Return Policy</p>
          <p class="small">Returns Centre</p>
          <p class="small">Franchise Enquiry</p>
          <p class="small">Careers</p>
        </div>
        <div class="col">
          <h7>ABOUT US</h7>
          <p class="small">who we are?</p>
          <p class="small">Management Team</p>
          <p class="small">Careers</p>
          <p class="small">CUSTOMER DELIGHT</p>
          <p class="small">contact Us </p>
          <p class="small">FAQ</p>
        </div>
      </div>
      <footer className="py-2 bg-dark m-0  text-white  ">
        <p className="m-0 text-center text-white">
          Copyright &copy; Sri Vinayaka Jewellery and Silk Sarees
        </p>
      </footer>
    </div>
  );
}

export default Footer;
