/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
export default function Goldprice() {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    const response = await fetch(
      process.env.REACT_APP_Rate_Api_Endpoint,
    );
    
    const data = await response.json();
  
    setUsers(data.rates)  
   console.log(users);
  };
  
  useEffect(() => {
    getUsers();
  }, [ ]);
  const today=new Date() ;
    const year = today.getFullYear();
    const month = today.getMonth()+1;
    const day = today.getDate(); 
    const currentDate=month+"-"+day+"-"+year;
  return (
    <div>
      {users.map((price) => {
        return (
          <div className="exe">
             <div class="gold-rate-box text-center">
          <div class="marquee">
            <p>
              Today Gold Price 1Gm <i class="fa fa-inr"></i>{price.goldrate}
            </p>
            <p>
              Today Silver Price 1Kg <i class="fa fa-inr"></i> {price.silverrate}{" "}
            </p>
            <p>
               Date <i class="fa fa-inr"></i> {currentDate}{" "}
            </p>
          </div>
        </div>
          </div>
        )
      })}
    </div>
  );
}
