import { Container, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);
function Goldrate() {
  // const [name, setName] = useState("");
  const [goldrate, setGoldrate] = useState("");
  const [silverrate, setSilverrate] = useState("");
  const [message, setMessage] = useState("");
  const date = new Date();
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const currentDate = month + "-" + day + "-" + year;
  console.log(currentDate);
  console.log(process.env.REACT_APP_Rate_Api_write)
  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch(
        process.env.REACT_APP_Rate_Api_write,
        {
          method: "POST",
          body: JSON.stringify({
            // name: name,
            goldrate: goldrate,
            silverrate: silverrate,
            currentDate: currentDate,
            Date: date,
          }),
        }
      );
      // let resJson = await res.json();
      if (res.status === 200) {
        // setName("");
        setGoldrate("");
        setSilverrate("");
        setMessage("Rate is updated successfully");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      <div>
        <h3>Fill Gold and Silver Rates</h3>
        <br />
        <Form>
          <Form.Group></Form.Group>
          {/* <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            /> 
          </Form.Group>*/}
          <Form.Group>
            <Form.Label>Gold Rate</Form.Label>
            <Form.Control
              onChange={(e) => setGoldrate(e.target.value)}
              placeholder="Gold Rate"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Silver Rate</Form.Label>
            <Form.Control
              onChange={(e) => setSilverrate(e.target.value)}
              placeholder="Silver Rate"
            />
          </Form.Group>
          <Button onClick={handleSubmit} type="submit">
            Submit
          </Button>

          <div className="message">{message ? <p>{message}</p> : null}</div>
        </Form>
      </div>
    </Container>
  );
}
export default Goldrate;
