import React from "react";
import g2 from "../assets/Gifts/g2.jpeg";
function GiftArticles() {
  return (
    <div className="giftarticles">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Gifts/g2.jpeg"
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Giftarticles</h1>
            <p>Gifts articles For marriages,Functions.</p>
            <h1>comming soon....</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftArticles;
