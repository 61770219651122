import React from "react";
import { Card, CardGroup } from "react-bootstrap";
export default function CardComponent() {
  return (
    <div>
      <div classname="card">
        <CardGroup>
          <Card>
            <Card.Img
              variant="top"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Daimond/Daimond.jpeg"
            />
            <Card.Body>
              <Card.Title>Daimonds</Card.Title>
              <Card.Text>
                Deep-blue diamonds rarely exceed a few carats in size.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Gold/gold2.jpg"
            />
            <Card.Body>
              <Card.Title>Gold</Card.Title>
              <Card.Text>
                The No. 1 gold quality store for with Valuable price.{" "}
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/silver/silver.jpeg"
            />
            <Card.Body>
              <Card.Title>Silver</Card.Title>
              <Card.Text>All pooja gifts available.</Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <div className="text">
        <p>OUR COLLECTIONS</p>
      </div>
      <div classname="cardgroup2">
        <CardGroup>
          <Card>
            <Card.Img
              variant="top"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Gifts/g1.jpg"
            />
            <Card.Body>
              <Card.Title>Giftarticles</Card.Title>
              <Card.Text>Gift Articles for marriages and functions.</Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Sarees/sarees1.jpeg"
            />
            <Card.Body>
              <Card.Title>Silk Sarees</Card.Title>
              <Card.Text>Quality silksarees. All types ar available.</Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Fashion/f1.png"
            />
            <Card.Body>
              <Card.Title>Fashion</Card.Title>
              <Card.Text>
                Fashion clothes available for all age groups.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <div className="text">
          <p>OUR COLLECTIONS</p>
        </div>
      </div>
      <CardGroup>
        <Card>
          <Card.Img
            variant="top"
            src="https://d32t9jl4s128ar.cloudfront.net/assets/Fashion/f1.png"
          />
          <Card.Body>
            <Card.Title>Fashion</Card.Title>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img
            variant="top"
            src="https://d32t9jl4s128ar.cloudfront.net/assets/Gold/gold3.jpg"
          />
          <Card.Body>
            <Card.Title>Jewellery</Card.Title>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}
