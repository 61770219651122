import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./styles.css";
import SearchBar from "./Searchbar";
export default function Socialmedia() {
  return (
    <div>
      {" "}
      <div className="social-container">
        <a
          href="https://www.youtube.com/watch?v=kePPV5rpzqQ"
          className="youtube social"
        >
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a
          href="https://www.facebook.com/srivinayakajewellers/"
          className="facebook social"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a
          href="https://twitter.com/SriVinayakaJew1"
          className="twitter social"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a
          href="https://instagram.com/sri_vinayaka_jewellers?utm_medium=copy_link"
          className="instagram social"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a>
          <SearchBar />
        </a>
      </div>
    </div>
  );
}
