import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import Analytics from "@aws-amplify/analytics";
import awsconfig from "../aws-exports";
import "./styles.css";
import { AiOutlineLogin } from "react-icons/ai";
import Home from "./Home";
Amplify.configure(awsconfig);
const NOTSIGNIN = "Register now";
const SIGNEDIN = "Congratulations  ";
const SIGNEDOUT = "You have logged out successfully";
const WAITINGFOROTP = "Enter OTP number";
const VERIFYNUMBER = "Verifying number";

function Login() {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [otp, setOtp] = useState("");
  const [number, setNumber] = useState("");
  const [ref, setRef] = useState("");
  const [firstName, setFirstName] = useState("");
  const [rate, setRate] = useState("");
  // const [flag, setFlag] = useState(null);
  const password = Math.random().toString(10) + "Abc#";
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log("Ready to auth");
    // Auth.currentCredentials();
    setTimeout(verifyAuth);
    Analytics.autoTrack("session", {
      enable: true,
    });

    if (localStorage.getItem("isLogged") == "true") {
      navigate("/Home");
    }
  }, [location]);

  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        setSession(null);
      })
      .catch((err) => {
        console.error(err);
        setMessage(NOTSIGNIN);
      });
  };

  const signOut = () => {
    if (user) {
      Auth.signOut();
      setUser(null);
      setOtp("");
      setMessage(SIGNEDOUT);
    } else {
      setMessage(NOTSIGNIN);
    }
  };

  const signIn = () => {
    setMessage(VERIFYNUMBER);
    Auth.signIn(number)
      .then((result) => {
        setSession(result);
        setMessage(WAITINGFOROTP);
      })
      .catch((e) => {
        if (e.code === "UserNotFoundException") {
          signUp();
        } else if (e.code === "UsernameExistsException") {
          setMessage(WAITINGFOROTP);
          signIn();
        } else {
          console.log(e.code);
          console.error(e);
        }
      });
  };

  const signUp = async () => {
    const result = await Auth.signUp({
      username: number,
      password,
      attributes: {
        phone_number: number,
        name: ref,
        website: firstName,
        locale: rate,
      },
    }).then(() => signIn());
    return result;
  };
  const Rating = () => {
    setRate(5);
  };
  const verifyOtp = () => {
    Auth.sendCustomChallengeAnswer(session, otp)
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        setSession(null);

        localStorage.setItem("isLogged", "true");
        navigate("/Home");
        window.location.reload(false);
      })
      .catch((err) => {
        signIn();
        setMessage(err.message);
        setOtp("");
        // setFlag(null);
        console.log(err);
      });
    return Home;
  };
  return (
    <div className="register">
      <div className="marq">
        <marquee>Register Here for future updates and discounts</marquee>
      </div>
      <div className="loginfield">
        <h6>Welcome to </h6>
        <h1>SRI VINAYAKA</h1>
        <h6>Jewellery & Silk Sarees</h6>
        {!user && !session && (
          <div className="loginfield">
            <label for="formControlInput" class="form-label">
              Name
            </label>
            <input
              placeholder="Enter Your Name"
              value={ref}
              onChange={(event) => setRef(event.target.value)}
            />

            <label for="formControlInput" class="form-label">
              Feedback
            </label>
            <div>
              <icon value={rate} onClick={() => setRate("5")}>
                <img
                  class="rating"
                  src="https://d32t9jl4s128ar.cloudfront.net/assets/ratingicons/1.png"
                />
              </icon>
              <icon value={rate} onClick={() => setRate("4")}>
                <img
                  class="rating"
                  src="https://d32t9jl4s128ar.cloudfront.net/assets/ratingicons/2.png"
                />
              </icon>
              <icon value={rate} onClick={() => setRate("3")}>
                <img
                  class="rating"
                  src="https://d32t9jl4s128ar.cloudfront.net/assets/ratingicons/3.png"
                />
              </icon>
              <icon value={rate} onClick={() => setRate("1")}>
                <img
                  class="rating"
                  src="https://d32t9jl4s128ar.cloudfront.net/assets/ratingicons/4.png"
                />
              </icon>
            </div>
            <label for="formControlInput" class="form-label">
              Review
            </label>

            <input
              placeholder="review"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <label for="formControlInput" class="form-label">
              Mobile
            </label>
            <PhoneInput
              placeholder="phone number"
              defaultCountry="IN"
              vaule={number}
              onChange={setNumber}
            />
            <Button
              class="btn-warning"
              variant="outline-secondary"
              onClick={signIn}
            >
              SUBMIT
            </Button>
          </div>
        )}
        {!user && session && (
          <div className="loginfield">
            <label for="formControlInput" class="form-label">
              Verify
            </label>
            <input
              placeholder="Your OTP"
              onChange={(event) => setOtp(event.target.value)}
              value={otp}
            />
            <Button
              class="btn-warning"
              variant="outline-secondary"
              onClick={verifyOtp}
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default Login;
