import React from "react";
import { Carousel, Card, Button } from "react-bootstrap";
import gold2 from "../assets/Gold/gold2.jpg";
import img from "../assets/img1.png";
import bnr1 from "../assets/bnr1.png";
import Badges from "./Badge";
import CardComponent from "./card";
import Socialmedia from "./Socialmedia";
function Home() {
  return (
    <div className="home">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://d32t9jl4s128ar.cloudfront.net/assets/bnr1.png"
          />
          {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://d32t9jl4s128ar.cloudfront.net/assets/Gold/img1.png"
          />

          {/* <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://d32t9jl4s128ar.cloudfront.net/assets/Gold/img1.png"
          />

          {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      <div className="text">
        <p>OUR COLLECTIONS</p>
      </div>
      <div>
        <CardComponent />
        <div className="socialmedia">
          <Socialmedia />
        </div>
      </div>
    </div>
  );
}

export default Home;
