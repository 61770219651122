import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { AiOutlineLogin} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import Analytics from "@aws-amplify/analytics";
import awsconfig from "../aws-exports";
import "./styles.css";
import Home from "./Home";

import { icon } from "@fortawesome/fontawesome-svg-core";
Amplify.configure(awsconfig);
const NOTSIGNIN = "Register now";
const SIGNEDIN = "Congratulations  ";
const SIGNEDOUT = "You have logged out successfully";
const WAITINGFOROTP = "Enter OTP number";
const VERIFYNUMBER = "Verifying number";

function Logout() {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [otp, setOtp] = useState("");
  const [number, setNumber] = useState("");
  const [ref, setRef] = useState("");
  const [firstName, setFirstName] = useState("");
  // const [flag, setFlag] = useState(null);
  const password = Math.random().toString(10) + "Abc#";
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log("Ready to auth");
    // Auth.currentCredentials();
    setTimeout(verifyAuth);
    Analytics.autoTrack("session", {
      enable: true,
    });
    if (localStorage.getItem("isLogged") == "false") {
      navigate("/review");
      
    }
  }, [location]);

  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        setSession(null);
      })
      .catch((err) => {
        console.error(err);
        setMessage(NOTSIGNIN);
      });
  };

  const signOut = () => {
    if (user) {
      Auth.signOut();
      setUser(null);
     
      setOtp("");
      setMessage(SIGNEDOUT);
      localStorage.setItem("isLogged", "false");
      navigate("/review");
      
    } else {
      setMessage(NOTSIGNIN);
    }
    
  };

  const signIn = () => {
    setMessage(VERIFYNUMBER);
    Auth.signIn(number)
      .then((result) => {
        setSession(result);
        setMessage(WAITINGFOROTP);
      })
      .catch((e) => {
        if (e.code === "UserNotFoundException") {
          signUp();
        } else if (e.code === "UsernameExistsException") {
          setMessage(WAITINGFOROTP);
          signIn();
        } else {
          console.log(e.code);
          console.error(e);
        }
      });
  };

  const signUp = async () => {
    const result = await Auth.signUp({
      username: number,
      password,
      attributes: {
        phone_number: number,
        name: ref,
        website: firstName,
      },
    }).then(() => signIn());
    return result;
  };

  const verifyOtp = () => {
    Auth.sendCustomChallengeAnswer(session, otp)
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        setSession(null);

      })
      .catch((err) => {
        signIn();
        setMessage(err.message);
        setOtp("");
        // setFlag(null);
        console.log(err);
      });
    return Home;
  };
  return (
    <div id="userlogin">
     
      <icon
      
        onClick={signOut}>
          <AiOutlineLogin size={"2em"}/>
        </icon>

      </div>
  );
}
export default Logout;
