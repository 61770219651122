/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import GenericTile from "react-generic-tile";
import { Auth } from "aws-amplify";
import Goldprice from "./Goldprice";
export default function Account() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      Auth.currentUserInfo({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((user) => {
          setUsername(user.username);
          setName(user.attributes.name);
          console.log(`Load additional settings for user: ${user.username}`);
          console.log(
            `Load additional settings for user: ${user.attributes.name}`
          );
          // TBD
        })
        .catch((err) => setError(err));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);
  return (

    <div >
      <GenericTile
        header={"Savings Scheme"}
        subheader={
          "Gold Saving Scheme Plan for buying marriage jewellery at affordable cost"
        }
        loading={false}
        // number={"65.5"}
        // scale={"MM"}
        color={"Good"}
        // indicator={"Up"}
        // footer={"This is only example.."}
      />
      <GenericTile
        header={"Monthly Schemes"}
        subheader={"small savings into lump sums"}
        loading={false}
        // number={"65.5"}
        // scale={"MM"}
        color={"Good"}
        // indicator={"Up"}
        // footer={"This is only example.."}
      />
      <GenericTile
        header={"Chit Fund"}
        subheader={"small savings into lump sums"}
        loading={false}
        // number={"65.5"}
        // scale={"MM"}
        color={"Good"}
        // indicator={"Up"}
        // footer={"This is only example.."}
      />
      <GenericTile
        header={"Gold Loan"}
        subheader={"small savings into lump sums"}
        loading={false}
        // number={"65.5"}
        // scale={"MM"}
        color={"Good"}
        // indicator={"Up"}
        // footer={"This is only example.."}
      />
      <GenericTile
        header={"MY Profile"}
        subheader={"small savings into lump sums"}
        loading={false}
        // number={"65.5"}
        // scale={"MM"}
        color={"Good"}
        // indicator={"Up"}
        // footer={"This is only example.."}
      />
      <GenericTile
        header={"E-KYC"}
        subheader={"small savings into lump sums"}
        loading={false}
        // number={"65.5"}
        // scale={"MM"}
        color={"Good"}
        // indicator={"Up"}
        // footer={"This is only example.."}
      />
      <Goldprice/>
    </div>
  );
}
