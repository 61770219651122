import React from "react";
import img from "../assets/bg.png";
import Home from "./Home";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { IoHome } from "react-icons/io5";

function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/bnr1.png"
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">About</h1>
            <p>
              Sri Vinayaka Jewellery and Silk Sarees more than jewellery. Our
              crafts are an obsession for both men and women. From bridal
              jewellery to traditional jewellery or one with a modern look sri
              vinayaka jewellery and silk sarees provides sales of all. With the
              presence of design stations, distribution centre, manufacturing
              facility, bullion desk and after sales service we are able to
              provide our customers with unbeaten quality and value for their
              money
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
