/* eslint-disable no-unused-vars */
import "./header.css";
import logo from "./logo.png";
import Logout from "../Logout";
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Goldprice from "../Goldprice";
export default function Headertest() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      Auth.currentUserInfo({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((user) => {
          setUsername(user.username);
          setName(user.attributes.name);
          console.log(`Load additional settings for user: ${user.username}`);
          console.log(
            `Load additional settings for user: ${user.attributes.name}`
          );
          // TBD
        })
        .catch((err) => setError(err));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);
  return (
    <header>
      <div id="header-left">
        <div id="time-date">
          <svg
            class="time-icon"
            width="20"
            height="20"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14 2.68322L10.78 0L9.877 1.06356L13.097 3.74677L14 2.68322ZM4.116 1.06356L3.22 0L0 2.67627L0.903 3.73982L4.116 1.06356ZM7.35 4.26812H6.3V8.43893L9.625 10.4201L10.15 9.56505L7.35 7.91758V4.26812ZM7 1.48759C3.521 1.48759 0.7 4.28898 0.7 7.74379C0.7 11.1986 3.514 14 7 14C10.479 14 13.3 11.1986 13.3 7.74379C13.3 4.28898 10.479 1.48759 7 1.48759ZM7 12.6097C4.291 12.6097 2.1 10.434 2.1 7.74379C2.1 5.05363 4.291 2.87786 7 2.87786C9.709 2.87786 11.9 5.05363 11.9 7.74379C11.9 10.434 9.709 12.6097 7 12.6097Z" />
          </svg>
          <span>Fri 8:00 - 18:00 / Sunday 8:00 - 14:00</span>
        </div>
        <Goldprice/>
        <div id="contact">
          <svg
            class="mobile-no-icon"
            width="18"
            height="18"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.23 9.62889C12.2733 9.62889 11.3478 9.47333 10.4844 9.19333C10.2122 9.1 9.90889 9.17 9.69889 9.38L8.47778 10.9122C6.27667 9.86222 4.21556 7.87889 3.11889 5.6L4.63556 4.30889C4.84556 4.09111 4.90778 3.78778 4.82222 3.51556C4.53444 2.65222 4.38667 1.72667 4.38667 0.77C4.38667 0.35 4.03667 0 3.61667 0H0.925556C0.505556 0 0 0.186667 0 0.77C0 7.99556 6.01222 14 13.23 14C13.7822 14 14 13.51 14 13.0822V10.3989C14 9.97889 13.65 9.62889 13.23 9.62889Z" />
          </svg>{" "}
          1-800-458-56987{" "}
          <svg
            class="location-icon"
            width="18"
            height="22"
            viewBox="0 0 10 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.9 0C2.191 0 0 2.191 0 4.9C0 8.575 4.9 14 4.9 14C4.9 14 9.8 8.575 9.8 4.9C9.8 2.191 7.609 0 4.9 0ZM4.9 6.65C3.934 6.65 3.15 5.866 3.15 4.9C3.15 3.934 3.934 3.15 4.9 3.15C5.866 3.15 6.65 3.934 6.65 4.9C6.65 5.866 5.866 6.65 4.9 6.65Z" />
          </svg>{" "}
          K.R Puram , Bengaluru - 560036
        </div>
      </div>
      <div id="svj-logo">
        <a href="/home">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <div id="header-right">
        <div class="cart-login">
          <svg
            class="cart-icon"
            width="40"
            height="40"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.3595 11.9048H17.8313V8.19048H21.5392V5.71429H17.8313V2H15.3595V5.71429H11.6516V8.19048H15.3595V11.9048ZM10.4157 23.0476C9.05613 23.0476 7.95614 24.1619 7.95614 25.5238C7.95614 26.8857 9.05613 28 10.4157 28C11.7752 28 12.8876 26.8857 12.8876 25.5238C12.8876 24.1619 11.7752 23.0476 10.4157 23.0476ZM22.7751 23.0476C21.4156 23.0476 20.3156 24.1619 20.3156 25.5238C20.3156 26.8857 21.4156 28 22.7751 28C24.1347 28 25.247 26.8857 25.247 25.5238C25.247 24.1619 24.1347 23.0476 22.7751 23.0476ZM10.6258 19.0238L10.6629 18.8752L11.7752 16.8571H20.983C21.91 16.8571 22.7257 16.3495 23.1459 15.5819L27.9167 6.90286L25.7661 5.71429H25.7538L24.3942 8.19048L20.983 14.381H12.3067L12.146 14.0467L9.37748 8.19048L8.20333 5.71429L7.04154 3.2381H3V5.71429H5.47189L9.9213 15.1114L8.25277 18.1448C8.05502 18.4914 7.94378 18.9 7.94378 19.3333C7.94378 20.6952 9.05613 21.8095 10.4157 21.8095H25.247V19.3333H10.9348C10.7741 19.3333 10.6258 19.1971 10.6258 19.0238Z" />
          </svg>

          <div id="user-login">
            <span>{name}</span>

            {/* <svg class="logout-icon" width="28" height="30" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg" >
                
              
                   <path d="M13.5 0H10.5V15H13.5V0ZM18.84 3.66L16.665 5.835C19.26 7.41 21 10.245 21 13.5C21 18.465 16.965 22.5 12 22.5C7.035 22.5 3 18.465 3 13.5C3 10.245 4.74 7.41 7.32 5.82L5.16 3.66C2.04 5.82 0 9.42 0 13.5C0 20.13 5.37 25.5 12 25.5C18.63 25.5 24 20.13 24 13.5C24 9.42 21.96 5.82 18.84 3.66Z" />
              </svg> */}
            <Logout />
            {/* <Logout class="logout-icon"/> */}
          </div>
          <div id="search-box">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                aria-label="Username"
                aria-describedby="input-group-right"
              />
              <span class="input-group-text" id="input-group-right-example">
                <i class="bi bi-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
