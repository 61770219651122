import React from "react";
import daimond from "../assets/Daimond.jpeg";

function Daimond() {
  return (
    <div className="daimond">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="https://d32t9jl4s128ar.cloudfront.net/assets/Daimond/Daimond.jpeg"
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Daimond</h1>
            <p>Deep-blue diamonds rarely exceed a few carats in size.</p>
            <h1>comming soon....</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Daimond;
