import React from "react";
import bg from "../assets/bg.png";
function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img className="img-fluid rounded mb-4 mb-lg-0" src={bg} alt="bg" />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Contact</h1>
            <p>
              K.C.J. Mansion, 613, Old Madras Rd, Opposite Katte Vinayaka
              Temple, Krishnarajapura, Bengaluru, Karnataka 560036
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
